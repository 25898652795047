@unocss layer core;

@layer core {

  :root {
    --color-primary: #ffffff;
    --color-secondary: #ffca44;
    --color-ternary: #ffe196;
  }

  :root.light {
    --color-primary: #ffffff;
    --color-secondary: #000000;
    --color-ternary: #ff981a;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Chathura';
    background: var(--color-secondary);
  }

  h1,
  h2,
  h3 {
    scroll-margin-top: 200px;
    /* Adjust this based on your header height */
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  li {
    padding: 0px;
    list-style: none;
    list-style-type: "- ";
  }

  blockquote {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .wallet-identicon {
    margin-top: -.5em;
  }

  @media only screen and (min-width: 600px) {}

  @media only screen and (max-width: 600px) {}

}
